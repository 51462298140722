<template>
  <v-col
    cols="12"
    md="12"
  >
  
    <!-- <v-row
      no-gutters
      class="pb-4"
    >
      <v-col cols="auto">
        <v-btn
          class="mr-2"
          color="secondary"
        >
          Все
        </v-btn>
      </v-col>

      <v-col
        cols="auto"
        class="actions-bar"
      >
        <v-btn
          color="green"
          @click="createDialog"
        >
          Добавить клиента
        </v-btn>
      </v-col> 
    </v-row> -->

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Клиенты</div>

        <div class="text-subtitle-1 font-weight-light">
          Общee количество: {{ clientsList.length }}
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="clientsList"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              color="red"
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Клиент</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.name"
                    label="Имя"
                    :error-messages="errors.name"
                    @input="errors.name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.phone"
                    label="Телефон"
                    :error-messages="errors.phone"
                    @input="errors.phone = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.address"
                    label="Адрес"
                    :error-messages="errors.phone"
                    @input="errors.phone = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.city_id"
                    :items="citiesList"
                    item-text="name"
                    item-value="id"
                    label="Город"
                    :error-messages="errors.city_id"
                    @change="errors.city_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.birth_date"
                    v-mask="'####-##-##'"
                    label="Дата рождения"
                    :error-messages="errors.birth_date"
                    @input="errors.birth_date = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.bonuses"
                    label="Бонусы"
                    type="number"
                    :error-messages="errors.bonuses"
                    @input="errors.bonuses = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editClient"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createClient"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteClient"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import ClientsService from '@/app/Services/ClientsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'

export default {
  name: 'ClientsDashboard',

  mixins: [TableDialogMixin],

  data() {
    return {
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Телефон',
          value: 'phone',
        },
        {
          sortable: false,
          text: 'Дата рождения',
          value: 'birth_date',
        },
        {
          sortable: false,
          text: 'Город',
          value: 'city.name',
        },
        {
          sortable: false,
          text: 'Бонусы',
          value: 'bonuses',
          align: 'right',
        },
        {
          text: 'Действия',
          value: 'actions',
          sortable: false,
        },
      ],
      clientsList: [],
      dialogData: {
        name: '',
        phone: '',
        bonuses: '',
        address: '',
        city_id: 0,
        birth_date: '',
      },
    }
  },

  computed: {
    citiesList() {
      return this.$store.state.references.cities
    },
  },

  async created() {
    await this.getClients()
  },

  methods: {
    async getClients() {
      this.loading = true

      const response = await ClientsService.getClients()

      if (response.status) {
        this.clientsList = response.data

        this.$store.dispatch('clients/setClientsData', response.data)
      }

      this.loading = false
    },

    async createClient() {
      if (this.$refs.form.validate()) {
        const response = await ClientsService.createClient(this.dialogData)

        if (response.status) {
          await this.getClients()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async editClient() {
      if (this.$refs.form.validate()) {
        const response = await ClientsService.editClient(this.selectedId, this.dialogData)

        if (response.status) {
          await this.getClients()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteClient() {
      const response = await ClientsService.deleteClient(this.selectedId)

      if (response.status) {
        await this.getClients()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },
  },
}
</script>
